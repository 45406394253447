<template>
    <div align="center" justify="center" class="pa-3">
        <Loading :active="loadingInicial" :is-full-page="true" />
        <v-card align="left" justify="left">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2"> mdi-poll</v-icon
                >Mensagens > Relatórios de envio de E-mails
            </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
        <v-card class="my-6">
            <v-row>
                <div class="ma-4 pa-2 mx-auto" max-width="400">
                    <v-row>
                        <v-col sm="12" lg="5">
                            <v-sheet
                                class="v-sheet--offset ml-3"
                                color="primary"
                                elevation="1"
                                max-width="100%"
                            >
                                <v-icon
                                    color="white"
                                    size="400%"
                                    class="ma-1 pa-1"
                                >
                                    mdi-chart-timeline-variant
                                </v-icon>
                            </v-sheet>
                        </v-col>
                        <v-col sm="12" lg="7">
                            <v-card-text class="pt-0">
                                <div class="font-weight-light grey--text">
                                    Total
                                </div>
                                <v-card-text
                                    class="
                                    font-weight-bold
                                    grey--text
                                    display-1
                                    text-no-wrap
                                "
                                >
                                    <v-progress-circular
                                        v-if="carregandoTotal"
                                        color="primary"
                                        small
                                        indeterminate
                                    />
                                    {{ resultadoTotal }}
                                </v-card-text>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-col>
                        <div class="subheading font-weight-light grey--text">
                            Número de envios na Base
                        </div>
                        <v-divider class="my-2"></v-divider>
                        <v-icon class="mr-2" small> mdi-clock </v-icon>
                        <span class="caption grey--text font-weight-light"
                            >Atualizado desde o último acesso</span
                        >
                    </v-col>
                </div>
                <div class="ma-4 pa-2 mx-auto" max-width="400">
                    <v-row>
                        <v-col sm="12" lg="5">
                            <v-sheet
                                class="v-sheet--offset ml-3"
                                color="primary"
                                elevation="1"
                                max-width="100%"
                            >
                                <v-icon
                                    color="white"
                                    size="400%"
                                    class="ma-1 pa-1"
                                >
                                    mdi-chart-timeline-variant
                                </v-icon>
                            </v-sheet>
                        </v-col>
                        <v-col sm="12" lg="7">
                            <v-card-text class="pt-0">
                                <div class="font-weight-light grey--text">
                                    Últimos 30 dias
                                </div>
                                <v-card-text
                                    class="
                                    font-weight-bold
                                    grey--text
                                    display-1
                                    text-no-wrap
                                "
                                >
                                    <v-progress-circular
                                        v-if="carregando30Dias"
                                        color="primary"
                                        small
                                        indeterminate
                                    />
                                    {{ resultado30Dias }}
                                </v-card-text>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-col>
                        <div class="subheading font-weight-light grey--text">
                            Número de envios na Base
                        </div>
                        <v-divider class="my-2"></v-divider>
                        <v-icon class="mr-2" small> mdi-clock </v-icon>
                        <span class="caption grey--text font-weight-light"
                            >Atualizado desde o último acesso</span
                        >
                    </v-col>
                </div>
                <div class="ma-4 pa-2 mx-auto" max-width="400">
                    <v-row>
                        <v-col sm="12" lg="5">
                            <v-sheet
                                class="v-sheet--offset ml-3"
                                color="primary"
                                elevation="1"
                                max-width="100%"
                            >
                                <v-icon
                                    color="white"
                                    size="400%"
                                    class="ma-1 pa-1"
                                >
                                    mdi-chart-timeline-variant
                                </v-icon>
                            </v-sheet>
                        </v-col>
                        <v-col sm="12" lg="7">
                            <v-card-text class="pt-0">
                                <div class="font-weight-light grey--text">
                                    Últimos 60 dias
                                </div>
                                <v-card-text
                                    class="
                                                                                                                                            font-weight-bold
                                                                                                                                            grey--text
                                                                                                                                            display-1
                                                                                                                                            text-no-wrap
                                                                                                                                        "
                                >
                                    <v-progress-circular
                                        v-if="carregando60Dias"
                                        color="primary"
                                        small
                                        indeterminate
                                    />
                                    {{ resultado60Dias }}
                                </v-card-text>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-col>
                        <div class="subheading font-weight-light grey--text">
                            Número de envios na Base
                        </div>
                        <v-divider class="my-2"></v-divider>
                        <v-icon class="mr-2" small> mdi-clock </v-icon>
                        <span class="caption grey--text font-weight-light"
                            >Atualizado desde o último acesso</span
                        >
                    </v-col>
                </div>
            </v-row>
        </v-card>
        <!-- Cabeçalho -->
        <div align="center" justify="center">
            <v-card @keyup.native.enter="botaoListarEnvios" class="pa-5 my-3">
                <v-row class="">
                    <v-col cols="4" xs="2" sm="2" md="3" lg="3" class="">
                        <v-text-field
                            solo
                            small
                            prepend-icon="mdi-chevron-right"
                            label="Código do Título"
                            hint="Informe o código do título para efetuar a busca"
                            placeholder="Ex.: 012345"
                            v-model="filtros.ds_nosso_numero"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="2" sm="2" md="3" lg="3" class="">
                        <v-text-field
                            solo
                            small
                            label="Nome do Destinatário"
                            hint="Informe o nome para efetuar a busca"
                            placeholder="Ex.: Fernando Alves"
                            clearable
                            v-model="filtros.nm_devedor"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="2" sm="2" md="3" lg="3" class="">
                        <v-text-field
                            solo
                            small
                            label="E-mail do Destinatário"
                            hint="Informe o email para efetuar a busca"
                            placeholder="Ex.: contato@email.com"
                            v-model="filtros.ds_email_devedor"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="10" xs="2" sm="2" md="3" lg="3" class="">
                        <v-select
                            solo
                            small
                            v-model="filtros.cd_status"
                            :items="listaStatus"
                            item-text="descricao"
                            item-value="codigo"
                            label="Status"
                        ></v-select>
                    </v-col>
                    <v-col cols="3" xs="2" sm="4" md="3" lg="3" class="">
                        <v-menu
                            ref="menuDtInicio"
                            v-model="menuDtInicio"
                            :close-on-content-click="false"
                            :return-value.sync="filtros.dt_inicio"
                            transition="scale-transition"
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    data-cy="dataMovimentoFilterMensageria"
                                    v-model="filtros.dt_inicio"
                                    solo
                                    label="Selecione a data inicial e final"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-card>
                                <v-card-text
                                    class="text-center"
                                    max-width="200px"
                                    >Importante: Selecione a data inicial e a
                                    data final</v-card-text
                                >
                                <v-date-picker
                                    v-model="filtros.dt_inicio"
                                    no-title
                                    range
                                    selected-items-text="Datas Selecionadas"
                                    width="25vw"
                                    scrollable
                                    color="primary"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menuDtInicio = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="
                                            $refs.menuDtInicio.save(
                                                filtros.dt_inicio
                                            );
                                            tratarRangeData();
                                        "
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-card>
                        </v-menu>
                    </v-col>

                    <v-col sm="12" md="12" lg="12" class="text-right">                        
                        <v-btn
                            :disabled="isDisabledExport || totalTitulos == 0"
                            color="green white--text"
                            class="mx-2"
                            @click="exportarXls"
                            data-cy="btnExportarXlsMensageria"
                            :loading="loading"
                        >
                            Exportar Xls
                            <v-icon class="ml-2">mdi-microsoft-excel</v-icon>
                        </v-btn>
                        <v-btn
                            color="green white--text"
                            @click="botaoListarEnvios"
                            :loading="loading"
                            data-cy="btnListarEnvios"
                            class=""
                        >
                            Buscar Resultados
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim do cabeçalho -->
            <v-data-table
                :headers="headers"
                :items="listaEnvios"
                :options.sync="options"
                :loading="loading"
                :server-items-length="totalTitulos"
                loading-text="Buscando envios..."
                item-key="comarcas"
                class="elevation-1"
                no-data-text="Nenhum resultado encontrado... Faça uma pesquisa"
                :footer-props="{
                    itemsPerPageOptions: [20, 100, 200, -1]
                }"
            >
                <template v-slot:[`item.dt_envio`]="{ item }">
                    <span>{{ formatDate(item.dt_envio) }}</span>
                </template>

                <template v-slot:[`item.dt_abertura`]="{ item }">
                    <span>{{ formatDate(item.dt_abertura) }}</span>
                </template>

                <template v-slot:[`item.dt_clique`]="{ item }">
                    <span>{{ formatDate(item.dt_clique) }}</span>
                </template>

                <template v-slot:[`item.dt_entregue`]="{ item }">
                    <span>{{ formatDate(item.dt_entregue) }}</span>
                </template>

                <template v-slot:[`item.cd_status`]="{ item }">
                    <span v-if="item.cd_status == '102'">
                        <p>Protestado</p>
                    </span>
                    <span v-if="item.cd_status == '225'">
                        <p>Apontado</p>
                    </span>
                    <span v-if="item.cd_status == '241'">
                        <p>Enviado a Cancelamento</p>
                    </span>
                    <span v-if="item.cd_status == '251'">
                        <p>Enviado a Anuência</p>
                    </span>
                    <span v-if="item.cd_status == '252'">
                        <p>Anuência Programada</p>
                    </span>
                </template>

                <template v-slot:[`item.st_ativo`]="{ item }">
                    <v-icon
                        :style="{
                            color: item.st_ativo != '' ? 'green' : 'red'
                        }"
                    >
                        {{ getBolean(item.st_ativo) }}</v-icon
                    >
                </template>

                <template v-slot:[`item.is_admin`]="{ item }">
                    <v-icon
                        :style="{
                            color: item.is_admin != '' ? 'green' : 'red'
                        }"
                    >
                        {{ getBolean(item.is_admin) }}</v-icon
                    >
                </template>

                <template v-slot:[`item.tracking`]="{ item }">
                    <span v-if="item.tracking == '2'" class="d-flex flex-row">
                        <v-tooltip bottom color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    elevation="0"
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    fab
                                    ><v-icon class="ma-2 pa-2 blue--text"
                                        >mdi-check-all</v-icon
                                    ></v-btn
                                >
                            </template>
                            <span>E-mail visualizado pelo destinatário</span>
                        </v-tooltip>
                        <v-tooltip bottom color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    elevation="0"
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    fab
                                >
                                    <v-icon class="ma-2 pa-2"
                                        >mdi-cursor-default-click</v-icon
                                    >
                                </v-btn>
                            </template>
                            <span>Essa mensagem recebeu cliques</span>
                        </v-tooltip>
                    </span>
                    <span v-if="item.tracking == '1'">
                        <v-tooltip bottom color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    x-small
                                    fab
                                    v-bind="attrs"
                                    v-on="on"
                                    elevation="0"
                                >
                                    <v-icon class="ma-2 pa-2 blue--text"
                                        >mdi-check-all</v-icon
                                    >
                                </v-btn>
                            </template>
                            <span>E-mail visualizado pelo destinatário</span>
                        </v-tooltip>
                    </span>
                    <span v-if="item.tracking == '0'">
                        <v-tooltip bottom color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    x-small
                                    fab
                                    v-bind="attrs"
                                    v-on="on"
                                    elevation="0"
                                >
                                    <v-icon class="ma-2 pa-2 grey--text"
                                        >mdi-check</v-icon
                                    ></v-btn
                                ></template
                            >
                            <span>E-mail Entregue ao destinatário</span>
                        </v-tooltip>
                    </span>
                    <span v-if="item.tracking == null">
                        <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    elevation="0"
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    fab
                                >
                                    <v-icon class="ma-2 pa-2"
                                        >mdi-dots-horizontal</v-icon
                                    >
                                </v-btn>
                            </template>
                            <span>E-mail enviado</span>
                        </v-tooltip>
                    </span>
                </template>

                <template v-slot:[`item.action2`]="{ item }">
                    <v-btn
                        small
                        elevation="1"
                        width="100%"
                        class="mr-2 grey white--text"
                        @click="exibirDetalhe(item)"
                        full-width
                        :style="{ color: 'primary' }"
                    >
                        <v-icon
                            class="mr-2"
                            @click="exibirDetalhe(item)"
                            :style="{ color: 'white' }"
                            >mdi-account-details</v-icon
                        >
                        ver título
                    </v-btn>
                </template>
            </v-data-table>
            <!-- Fim tabela resultados -->
        </div>
        <Snackbar
            v-if="snackbarDialog"
            :mostrarSnackbar="snackbarDialog"
            :corSnackbar="snackbarColor"
            :mensagemSnackbar="mensagemRetornoSnackbar"
            @fecharSnackbar="snackbarDialog = false"
        />
        <v-dialog
            v-model="dialog"
            max-width="1200px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Informações sobre o titulo -->
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn class="ml-0" icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Detalhes do Título</v-toolbar-title>
                        <v-spacer />
                        <!-- Botão em Desenvolvimento, aguardando url de requisição para entrar em funcionamento -->
                    </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-8 py-4">
                    <DetalheTitulo
                        :titulo="titulo"
                        @fecharModal="closeDialog"
                    />
                    <HistoricoTitulo
                        class="mt-3"
                        :historico_titulo="historicos"
                    />
                </v-card-text>
            </v-card>
            <!-- fim das informações sobre o titulo--------------------- -->
        </v-dialog>
        <v-dialog v-model="modalEmailXls" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório via e-mail</v-card-title
                    >
                    <v-card-text v-if="filtros != null">
                        O relatórios será aplicado com os seguintes filtros:
                    </v-card-text>
                    <v-card-text v-if="filtros.ds_nosso_numero != null">
                        <v-icon>mdi-information-outline</v-icon> Número do
                        Título:
                        {{ filtros.ds_nosso_numero }}
                    </v-card-text>
                    <v-card-text v-if="filtros.nm_devedor != null">
                        <v-icon>mdi-information-outline</v-icon> Nome do
                        Devedor:
                        {{ filtros.nm_devedor }}
                    </v-card-text>
                    <v-card-text v-if="filtros.ds_email_devedor != null">
                        <v-icon>mdi-information-outline</v-icon> E-mail do
                        Devedor:
                        {{ filtros.ds_email_devedor }}
                    </v-card-text>
                    <v-card-text v-if="filtros.cd_status != null">
                        <v-icon>mdi-information-outline</v-icon> Código do
                        Status:
                        {{ filtros.cd_status }}
                    </v-card-text>
                    <v-card-text v-if="filtros.dt_inicio != null">
                        <v-icon>mdi-information-outline</v-icon> Data de início
                        dos envios: {{ filtros.dt_inicio }}
                    </v-card-text>
                    <v-card-text v-if="filtros.dt_fim != null">
                        <v-icon>mdi-information-outline</v-icon> Data de fim dos
                        envios: {{ filtros.dt_fim }}
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-form ref="formEmail" v-model="validEmail">
                        <v-text-field
                            prepend-icon="person"
                            name="email"
                            label="E-mail"
                            type="email"
                            v-model="emailReport"
                            :rules="emailRules"
                            :loading="loadingFiltros"
                            required
                            data-cy="emailReportMensageria"
                        ></v-text-field>
                    </v-form>
                    <v-card-subtitle
                        >Digite o e-mail onde deseja receber o
                        relatório</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="exportarRelatoriosMsgs()"
                        :disabled="checkForm()"
                        data-cy="btnEnviarEmailRelatorioMensageria"
                    >
                        Enviar
                        <v-icon class="ml-1">chevron_right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modalConfirmacao" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório solicitado com sucesso.</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-card-subtitle
                        >O relatório será enviado para o seu
                        e-mail!</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        v-show="modalConfirmacao"
                        @click="modalConfirmacao = false"
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import MensageriaService from '@/services/MensageriaService';
import TituloService from '@/services/TituloService';
import HistoricoTitulo from '@/components/HistoricoTitulo';
import Titulo from '@/domains/Titulo';
import DetalheTitulo from '@/components/DetalheTitulo';
import Loading from 'vue-loading-overlay';
import {
    getCorrectTimezoneOffsetDate,
    getDataAtualDatePicker,
    getDataHoraSubtraida,
    getDataDatePicker,
} from '../helpers/Utilitarios';
import moment from 'moment';

export default {
    name: 'relatorioMensageria',
    mixins: [validationMixin],
    components: {
        DetalheTitulo,
        HistoricoTitulo,
        Loading
    },

    validations: {
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },

    data() {
        return {
            snackbarTimeout: null,
            snackbarColor: null,
            snackbarDialog: null,
            snackbarIcon: null,
            mensagemRetornoSnackbar: null,
            registros_pagina: 10,
            numero_paginas: 1,
            total_paginas_visible: 10,
            totalTitulos: 0,
            options: {
                page: 1,
                itemsPerPage: 20
            },
            counter: null,
            historicos: [],
            loading: false,
            ultimoTitulo: [],
            titulo: new Titulo(),
            emailRules: [
                v => !!v || 'E-mail é requerido',
                v => /.+@.+/.test(v) || 'E-mail inválido'
            ],
            emailReport: null,
            loadingInicial: false,
            modalEmailXls: false,
            modalConfirmacao: false,
            validEmail: false,
            modelRelatorioXlsEmail: false,
            dataFull: null,
            ultimos30Dias: null,
            resultado30Dias: null,
            dialogLoading: false,
            carregandoTotal: false,
            carregando30Dias: false,
            carregando60Dias: false,
            ultimos60Dias: null,
            resultado60Dias: null,
            resultadoTotal: null,
            menuDtFinal: false,
            menuDtInicio: false,
            modal: false,
            menuDataCadastroRange: false,
            listaEnvios: [],
            graficos: {
                dt_inicio: null,
                dt_fim: null
            },
            filtros: {
                cd_apresentante: null,
                ds_nosso_numero: null,
                nm_devedor: null,
                ds_email_devedor: null,
                cd_status: null,
                dt_inicio: null,
                dt_fim: null
            },
            listaStatus: [
                { codigo: '', descricao: 'Todos' },
                { codigo: '102', descricao: 'Protestado' },
                { codigo: '225', descricao: 'Apontado' },
                { codigo: '241', descricao: 'Enviado a Cancelamento' },
                { codigo: '251', descricao: 'Enviado a Anuência' },
                { codigo: '252', descricao: 'Anuência Programada' }
            ],
            dialog: false,
            listaTotalEnvios: null,
            dialogEditar: false,
            loadingFiltros: null,
            data_inicio: null,
            data_fim: null,
            menu1: false,
            modal2: false,
            cartao: true,
            boleto: true,
            status: true,
            id: null,
            ultimoFiltro: {
                cd_apresentante: null,
                ds_nosso_numero: null,
                nm_devedor: null,
                ds_email_devedor: null,
                cd_status: null,
                dt_inicio: null,
                dt_fim: null
            },
            pagina: 1,
            showPagination: false,
            comarca: [],
            snackbar: false,
            mensagemRetorno: null,
            mensagem: null,
            headers: [
                {
                    text: 'Id',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Código do Título',
                    sortable: false,
                    value: 'ds_nosso_numero'
                },
                {
                    text: 'Nome Destinatário',
                    sortable: false,
                    value: 'nm_devedor'
                },
                {
                    text: 'E-mail Destinatário',
                    sortable: false,
                    value: 'ds_email_devedor'
                },
                {
                    text: 'Data de envio',
                    sortable: false,
                    value: 'dt_envio'
                },
                {
                    text: 'Entrega',
                    sortable: false,
                    value: 'dt_entregue'
                },
                {
                    text: 'Visualização',
                    sortable: false,
                    value: 'dt_abertura'
                },
                {
                    text: 'Clique',
                    sortable: false,
                    value: 'dt_clique'
                },
                {
                    text: 'Acompanhamento',
                    sortable: false,
                    value: 'tracking'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'cd_status'
                },
                { text: ' ', sortable: false, value: 'action' },
                { text: ' ', sortable: false, value: 'action2' }
            ]
        };
    },
    watch: {
        options: {
            handler() {
                if (this.totalTitulos > 0) {
                    this.aplicarFiltros();
                }
            },
            deep: true
        }
    },

    methods: {
        sorterFilter() {
            return new Promise(resolve => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                let items = this.listaEnvios;
                const total = items.length;

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]];
                        const sortB = b[sortBy[0]];

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1;
                            if (sortA > sortB) return -1;
                            return 0;
                        } else {
                            if (sortA < sortB) return -1;
                            if (sortA > sortB) return 1;
                            return 0;
                        }
                    });
                }

                if (itemsPerPage > 0) {
                    items = items.slice(
                        (page - 1) * itemsPerPage,
                        page * itemsPerPage
                    );
                }

                setTimeout(() => {
                    resolve({
                        items,
                        total
                    });
                }, 1000);
            });
        },
        tratarRangeData() {
            this.filtros.dt_inicio.sort((a, b) => {
                return (
                    getCorrectTimezoneOffsetDate(new Date(a)) -
                    getCorrectTimezoneOffsetDate(new Date(b))
                );
            });
        },
        buscarEnvios(dataInicial, dataFinal) {
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            const filtros = this.filtros;
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;
                try {
                    let service = new MensageriaService(Vue.http, this.$store);
                    service
                        .buscarEnviosPaginados(
                            filtros,
                            page,
                            itemsPerPage,
                            dataInicial,
                            dataFinal
                        )
                        .then(
                            response => {
                                if (response.status == 200) {
                                    response.status;
                                    response.statusText;
                                    response.headers.get('Expires');
                                    this.exportActive = false;
                                    const {
                                        titulos,
                                        numero_linhas: total
                                    } = response.body;

                                    resolve({
                                        titulos,
                                        total
                                    });
                                }
                            },
                            error => {
                                if (
                                    error.body.msg ==
                                    'Session expired! Authentication is required!'
                                ) {
                                    this.mensagem = true;
                                    this.mensagemRetorno =
                                        'Sua sessão expirou, faça login novamente para ter acesso ao sistema (' +
                                        error.body.msg +
                                        ') ';
                                } else {
                                    this.setSnackbar(
                                        '3000',
                                        'error',
                                        'Erro ao realizar consulta.'
                                    );
                                }
                            }
                        )
                        .finally(() => {
                            this.loadingFiltros = false;
                            this.loading = false;
                        });
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        aplicarFiltros() {
            const filtros = this.filtros;

            let dataInicial;
            let dataFinal;

            if (filtros.dt_inicio === null || filtros.dt_inicio[0] === null) {
                this.setValoresIniciaisDatePicker();
            }

            const dataRange = this.filtros.dt_inicio;
            if (this.filtros.dt_inicio.length === 1) {
                dataInicial = dataRange[0];
                dataFinal = dataRange[0];
            } else {
                dataInicial = dataRange[0];
                dataFinal = dataRange[1];
            }

            this.buscarEnvios(dataInicial, dataFinal).then(data => {
                this.listaEnvios = data.titulos;
                this.totalTitulos = data.total;
                this.ultimoFiltro = {...this.filtros}
                if (data.total === 0) {
                    this.setSnackbar(
                        '3000',
                        'orange',
                        'A Consulta não retornou nenhum resultado.'
                    );
                    this.numero_paginas = 1;
                } else {
                    this.setSnackbar(
                        '3000',
                        'success',
                        'Consulta realizada com sucesso'
                    );
                    this.mensagem = null;
                    this.numero_paginas =
                        data.total % this.registros_pagina == 0
                            ? data.total / this.registros_pagina
                            : parseInt(data.total / this.registros_pagina) + 1;
                }
                this.showPagination = this.numero_paginas > 1;
                this.sorterFilter();
            });
        },
        setSnackbar(
            snackbarTimeout,
            snackbarColor,
            mensagemRetornoSnackbar,
            snackbarIcon = 'mdi-text-box-check-outline'
        ) {
            this.snackbarDialog = true;
            this.snackbarTimeout = snackbarTimeout;
            this.snackbarIcon = snackbarIcon;
            this.snackbarColor = snackbarColor;
            this.mensagemRetornoSnackbar = mensagemRetornoSnackbar;
        },
        closeDialog() {
            this.dialog = false;
        },
        exibirDetalhe(titulo) {
            this.dialog = true;
            this.titulo = new Titulo();
            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService.detalharTitulo(titulo).then(
                response => {
                    const { detalhe_titulo, historico_titulo } = response.body;
                    this.titulo = detalhe_titulo;
                    this.historicos = historico_titulo;
                    this.dialog = true;
                },
                error => {
                    this.mensagem = error;
                    console.log(error);
                }
            );
        },
        exportarRelatoriosMsgs() {
            this.filtros.cd_apresentante = this.$store.getters.cdApresentante;
            let mensageriaService = new MensageriaService(
                Vue.http,
                this.$store
            );
            this.loadingFiltros = true;
            var emails = [];
            emails[0] = this.emailReport;

            mensageriaService
                .exportarXlsEmail(this.filtros, emails)
                .then(response => {
                    console.log(response);
                    this.modalEmailXls = false;
                    this.modalConfirmacao = true;
                    this.emailReport = null;
                    this.loadingFiltros = false;
                })
                .catch(() => {
                    this.loadingFiltros = false;
                    this.modalEmailXls = false;
                    this.modalConfirmacao = true;
                    this.emailReport = null;
                });
        },
        checkformSubmit() {
            const check = this.checkForm();
            if (!check) {
                this.exportarEmailSQS();
            }
        },
        checkForm() {
            if (this.validEmail) {
                return false;
            }
            return true;
        },
        exportarXls() {
            this.modalEmailXls = true;
        },
        formatDate(item) {
            let dataFormatada;
            if (item) {
                dataFormatada = moment(item).format('DD/MM/YYYY');
            } else {
                dataFormatada = '--';
            }
            return dataFormatada;
        },
        get30Dias() {
            let dataAtual = new Date();
            //console.log('data atual: ' + dataAtual);
            let ano = dataAtual.getFullYear();
            //console.log('ano: ' + ano);
            let mes = dataAtual.getMonth() + 1;
            //console.log('mes: ' + mes);
            let dia = dataAtual.getDate();
            //console.log('dia: ' + dia);

            if (mes == 1) {
                ano = dataAtual.getFullYear() - 1;
                mes = dataAtual.getMonth() + 12;
            }

            let ultimos30Dias = ano + '-' + String(mes - 1).padStart(2, '0') + '-' + String(dia).padStart(2, '0');
            //console.log('Data 30 dias: ' + ultimos30Dias);
            this.ultimos30Dias = ultimos30Dias;

            var diaAtual =
                dataAtual.getFullYear() +
                '-' +
                String(dataAtual.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(dataAtual.getDate()).padStart(2, '0');
            this.diaAtual = diaAtual;
            //console.log('Data Atual: ' + diaAtual);
        },
        get60Dias() {
            let dataAtual = new Date();
            // console.log('data atual: ' + dataAtual);
            let ano = dataAtual.getFullYear();
            //console.log('ano: ' + ano);
            let mes = dataAtual.getMonth() + 1;
            //console.log('mes: ' + mes);
            let dia = dataAtual.getDate();
            //console.log('dia: ' + dia);

            if (mes == 1) {
                ano = dataAtual.getFullYear() - 1;
                mes = dataAtual.getMonth() + 12;
            }

            let ultimos60Dias = ano + '-' + String(mes - 2).padStart(2, '0') + '-' + String(dia).padStart(2, '0');
            //console.log('Data 60 dias: ' + ultimos60Dias);
            this.ultimos60Dias = ultimos60Dias;

            var diaAtual =
                dataAtual.getFullYear() +
                '-' +
                String(dataAtual.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(dataAtual.getDate()).padStart(2, '0');
            this.diaAtual = diaAtual;
            //console.log('Data Atual: ' + diaAtual);
        },
        relatorio30Dias() {
            this.get30Dias();
            let graficos = this.graficos;
            //console.log('Relatório 30 dias | gráficos: ' + graficos);
            graficos.dt_inicio = this.ultimos30Dias;
            //console.log('Relatório 30 dias | dt_inicio: ' + graficos.dt_inicio);
            graficos.dt_fim = this.diaAtual;
            //console.log('Relatório 60 dias | dt_fim: ' + graficos.dt_fim);
            return new Promise((resolve, reject) => {
                try {
                    let mensageriaService = new MensageriaService(
                        Vue.http,
                        this.$store
                    );
                    mensageriaService.buscarEnviosPeriodo(graficos).then(
                        response => {
                            if (response.status == 200) {
                                this.carregando30Dias = false;
                                this.resultado30Dias =
                                    response.data.numero_linhas;
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        relatorio60Dias() {
            this.get60Dias();
            let graficos = this.graficos;
            //console.log('Relatório 60 dias | gráficos: ' + graficos);
            graficos.dt_inicio = this.ultimos60Dias;
            //console.log('Relatório 60 dias | dt_inicio: ' + graficos.dt_inicio);
            graficos.dt_fim = this.diaAtual;
            //console.log('Relatório 60 dias | dt_fim: ' + graficos.dt_fim);
            return new Promise((resolve, reject) => {
                try {
                    let mensageriaService = new MensageriaService(
                        Vue.http,
                        this.$store
                    );
                    mensageriaService.buscarEnviosPeriodo(graficos).then(
                        response => {
                            if (response.status == 200) {
                                this.carregando60Dias = false;
                                this.resultado60Dias =
                                    response.data.numero_linhas;
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        relatorioTotal() {
            let graficos = this.graficos;
            //console.log('Relatório Total | gráficos: ' + graficos);
            graficos.dt_inicio = null;
            // console.log(
            //     'Relatório Total | gráficos.dt_inicio: ' + graficos.dt_inicio
            // );
            graficos.dt_fim = null;
            // console.log(
            //     'Relatório Total | gráficos.dt_fim: ' + graficos.dt_fim
            // );
            return new Promise((resolve, reject) => {
                try {
                    let mensageriaService = new MensageriaService(
                        Vue.http,
                        this.$store
                    );
                    mensageriaService.buscarEnviosPeriodo(graficos).then(
                        response => {
                            if (response.status == 200) {
                                this.carregandoTotal = false;
                                this.resultadoTotal =
                                    response.data.numero_linhas;
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        limparDataCadastro() {
            this.filtros.dt_cadastro_range = [];
        },
        botaoListarEnvios() {
            this.loading = true;
            //this.filtrar = true;
            this.ultimoFiltro = {...this.filtros};
            this.listaEnvios = [];
            this.aplicarFiltros();
        },
        iniciarLoadings() {
            this.carregandoTotal = true;
            this.carregando30Dias = true;
            this.carregando60Dias = true;
        },
        setValoresIniciaisDatePicker() {
            const dataFinal = new Date();
            const dataFinalDatePicker = getDataAtualDatePicker();
            let dataInicial;
            if (
                (this.filtros.ds_nosso_numero === null &&
                    this.filtros.ds_email_devedor === null) ||
                this.filtros.ds_nosso_numero === '' ||
                this.filtros.ds_email_devedor === ''
            ) {
                dataInicial = getDataHoraSubtraida(dataFinal, 31);
                const dataInicialDatePicker = getDataDatePicker(dataInicial);
                this.filtros.dt_inicio = [
                    dataInicialDatePicker,
                    dataFinalDatePicker
                ];
            } else {
                this.filtros.dt_inicio = [null];
            }
        }
    },
    computed: {
        isDisabledExport: function() {
            //compara os filtros atuais com os filtros da última consulta e desabilita o btn de exportar se forem diferentes
            if(this.filtros.nm_devedor !== this.ultimoFiltro.nm_devedor) {
                return true;
            }else if(this.filtros.ds_email_devedor !== this.ultimoFiltro.ds_email_devedor) {
                return true;
            }else if(this.filtros.ds_nosso_numero !== this.ultimoFiltro.ds_nosso_numero) {
                return true;
            }else if(this.filtros.cd_status !== this.ultimoFiltro.cd_status) {
                return true;
            }else if(this.filtros.dt_inicio !== this.ultimoFiltro.dt_inicio) {
                return true;
            }else if(this.filtros.dt_fim !== this.ultimoFiltro.dt_fim) {
                return true;
            }else {
                return false;
            }
        }
    },
    async mounted() {
        this.iniciarLoadings();
        this.relatorio30Dias();
        this.relatorio60Dias();
        this.relatorioTotal();
        //this.setValoresIniciaisDatePicker();

        this.loadingInicial = true;
        this.loadingInicial = false;
    }
};
</script>
